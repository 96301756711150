import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
	Flex,
	Heading,
	Paragraph,
	Block
} from 'reakit'

import { Container, Sidebar, Body, Form, Modal, Footer } from '../../components/layouts'

import {
	StatCard,
	DonationsChart,
	Card,
	Loader,

} from '../../components/ui'

import {
	getDonations,
	getStatistics,
	deleteProject,
	editProj,
	clearProjectStats,
	refreshToken
} from '../../factory'

import Layout from '../../components/layout'

import theme from '../../theme/content'

class Project extends React.Component { //eslint-disable-line
	constructor(props) {
		super(props)

		this.state = {
			bodySize: 0,
			timeout: true
		}

		this.getStats = this.getStats.bind(this)
		this.getBodyWidth = this.getBodyWidth.bind(this)
		this.setValues = this.setValues.bind(this)
		this.handleDeleteProject = this.handleDeleteProject.bind(this)
		this.setProjId = this.setProjId.bind(this)
		this.updateProject = this.updateProject.bind(this)
		this.validateValues = this.validateValues.bind(this)
		this.preLoadValues = this.preLoadValues.bind(this)
	}

	componentDidMount() {
		const queryURL = new URLSearchParams(location.search)
		const projectId = queryURL.get('projectId')
		const {startDate, endDate} = this.props.stats

		if (projectId) {
			this.props.refreshToken().then((res) => {
				this.getStats(projectId, startDate, endDate, res)
			})
		} else {
			window.location.replace('/admin/projects/')
		}

		this.getBodyWidth()
		window.addEventListener('resize', this.getBodyWidth.bind(this))
		this.props.onClearProjectStats()

		setTimeout(()=>
			this.setState({timeout: false}),
		5000
		)

		// this.preLoadValues()
	}

	componentWillReceiveProps(nextProps) {
		const {startDate, endDate} = nextProps.stats
		if (startDate.format('MM-DD-YY') !== this.props.stats.startDate.format('MM-DD-YY')
			|| endDate.format('MM-DD-YY') !== this.props.stats.endDate.format('MM-DD-YY') ) {
			this.getStats(this.props.project.id, startDate, endDate, '')
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.getBodyWidth.bind(this))
	}

	getBodyWidth() {
		this.setState({
			bodySize: (window.innerWidth - 280)
		})
	}

	setProjId() {
		const queryURL = new URLSearchParams(location.search)
		const project_id = queryURL.get('projectId')
		this.setState({project_id})
	}

	getStats(project_id, startDate, endDate, token) {
		const date_from = startDate.format('MM-DD-YY') + " 00:00:00"
  		const date_to = endDate.format('MM-DD-YY') + " 23:59:59"

		const statsParams = {
			project_id,
			organization_id: this.props.organization.id,
			date_from,
			date_to,
			period: 1
		}


		this.props.onGetStatistics(statsParams, token ? token : this.props.token)
	}

	setValues(e) {
		const target = e.target
		const name = target.name
		const value = target.value

		if (target) {
			this.setState({
				[name]: value,
			})
		}
	}

	handleDeleteProject() {
		const queryURL = new URLSearchParams(location.search)
		const project_id = queryURL.get('projectId')

		this.props.onDeleteProject(project_id, this.props.token)
	}

	updateProject() {
		const data = Object.assign({}, this.props.project, this.state)
		this.props.onEditProj(data, this.props.token)
	}

	validateValues() {
		const data = Object.assign({}, this.props.project, this.state)
		const {end_date, donates_goal, name, description} = data
		const now = moment()
		if (!(end_date && donates_goal && name && description)) {
			this.setState({errors: 'All fields are required.'})
			return false
		}
		const [month, day, year] = end_date.split('-')
		if (!(month.length === 2 && day.length === 2 && year.split(' ')[0].length === 2)) {
			this.setState({errors: 'The date must be in the format MM-DD-YY hh:mm:ss'})
			return false
		}
		if (now.isAfter(moment(end_date, 'MM-DD-YYYY'))) {
			this.setState({errors: 'The end date must be in the future.'})
			return false
		}

		this.setState({errors: null})
		return true
	}

	preLoadValues() {
		this.setState({...this.props.project})
	}

	render() {
		const content = theme.pagesContent.project

		console.log(this.props.pending)

		return (
			<Layout>
				<Container>
					<Sidebar content={theme.adminSidebar} />
					<Body showDate>
						<Card>
							{this.props.stats.sumPerDay && (this.props.statsPending || this.props.stats.sumPerDay.length <= 0) ?
								<Block marginBottom="1em">
									<Loader />
								</Block> :
								<DonationsChart data={this.props.stats.sumPerDay} width={this.state.bodySize} />
							}
						</Card>
						<Flex row minHeight={'8em'} justifyContent="space-between" width="100%" marginBottom="1em">
							{this.props.organization && <StatCard
								title={'The Who'}
								stat={this.props.project.donor_qty || 0}
								loading={!this.props.project.donor_qty && this.props.project.donor_qty !== 0}
								description={'Total Donors'}
								totalCards={4}
							/>}
							{this.props.stats && <StatCard
								title={'The What'}
								stat={this.props.project && this.props.project.donates_current ? this.props.stats.quantity : 0}
								loading={!this.props.stats.quantity && this.props.stats.quantity !== 0 }
								description={'# Donations'}
								totalCards={4}
							/>}
							{this.props.stats && <StatCard
								title={'The How'}
								stat={this.props.project && this.props.project.donates_current ? this.props.stats.avgSum : 0}
								loading={!this.props.stats.avgSum && this.props.stats.avgSum !== 0 }
								type={'money'}
								description={'Avg. Donation'}
								totalCards={4}
							/>}
							{this.props.stats && <StatCard
								title={'The Impact'}
								stat={(this.props.project && this.props.project.donates_current) || 0}
								loading={this.props.project && (!this.props.project.donates_current && this.props.project.donates_current !== 0)}
								type={'money'}
								description={'Total Donated'}
								totalCards={4}
							/>}
						</Flex>
						<Flex row>
							<Card>
								<Flex row justifyContent="space-between">
								  <Heading as="h4">{this.state.name || (this.props.project ? this.props.project.name : '')}</Heading>
									<Modal
										button={{
											backgroundColor: 'transparent',
											text: 'Delete'
										}}
									>
										<Heading as="h4">Are you sure?</Heading>
										<Paragraph>To confirm, please type the project name, <strong>{this.props.project ? this.props.project.name : ''}</strong>, below:</Paragraph>
										<Form
											fields={
												[
													[
														{
															label: 'Project Name',
															name: 'delete_name',
															placeholder: (this.props.project ? this.props.project.name : ''),
															field: 'default',
															type: 'text',
															maxWidth: '100%',
															style: {
																background: 'inherit',
															},
														},
													],
												]
											}
											submitForm={this.handleDeleteProject}
											setValues={this.setValues}
											disabled={this.props.project && !(this.state.delete_name === this.props.project.name)}
											submitBtnText={"Delete"}
											logging={this.props.pending}
										/>
									</Modal>
								</Flex>
								<Form
									validate={this.validateValues}
									fields={content.projectForm.fields}
									defaultValues={this.props.project}
									values={this.state.project}
									setValues={this.setValues}
									submitForm={this.updateProject}
									submitBtnText={content.projectForm.submitField.text}
									error={this.state.errors}
									logging={this.props.pending && (this.props.pending || this.props.projectPending)}
								/>
							</Card>
						</Flex>
						<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
							<Footer />
						</Flex>
					</Body>
				</Container>
			</Layout>
		)
	}
}

Project.propTypes = {
	onGetDonations: PropTypes.func,
	onGetStatistics: PropTypes.func,
	donations: PropTypes.array,
	organization: PropTypes.object,
	project: PropTypes.object,
	stats: PropTypes.object,
	token: PropTypes.string,
	onDeleteProject: PropTypes.func,
	pending: PropTypes.bool,
	onEditProj: PropTypes.func,
	onClearProjectStats: PropTypes.func,
	statsPending: PropTypes.bool,
	refreshToken: PropTypes.func,
	projectPending: PropTypes.bool
}

const mapStateToProps = (state) => {
	return {
		organization: state.authState.adminOrg || state.adminState.organization,
		project: state.adminState.selectedProject,
		donations: state.adminState.donations,
		stats: state.statistics,
		token: state.authState.accessToken,
		pending: state.adminState.pending,
		projectPending: state.donateNowState.pending,
		statsPending: state.statistics.pending,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onGetDonations: (orgId, token) => dispatch(getDonations(orgId, token)),
		onGetStatistics: (params, token) => dispatch(getStatistics(params, token)),
		onDeleteProject: (id, token) => dispatch(deleteProject(id, token)),
		onEditProj: (data, token) => dispatch(editProj(data, token)),
		onClearProjectStats: () => dispatch(clearProjectStats()),
		refreshToken: (token) => dispatch(refreshToken(token))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Project)
